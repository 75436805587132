<section
  class="px-4 py-8 mb-12 bg-white border-t border-gray-200 md:py-8 md:mb-0"
>
  <div class="container flex flex-col gap-4 mx-auto">
    <div class="flex items-center justify-between w-full">
      <img src="\footer\logo-malirang.svg" alt="말이랑 푸터 로고" />
      <div class="flex items-center gap-4 *:cursor-pointer">
        <img
          src="\footer\logo-kakao.svg"
          (click)="goToLink('kakao')"
          alt="카카오 로고"
        />
        <img
          src="\footer\logo-naver.svg"
          (click)="goToLink('naver')"
          alt="네이버 로고"
        />
        <img
          src="\footer\logo-insta.svg"
          (click)="goToLink('insta')"
          alt="인스타 로고"
        />
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <div class="flex items-center gap-3">
        @for (term of terms; track $index) {
          <button
            [routerLink]="['/term', term.value]"
            class="text-sm font-semibold text-gray-500 transition-all duration-300 ease-in-out hover:opacity-80"
          >
            {{ term.label }}
          </button>

          <div
            [ngClass]="
              $index === terms.length - 1
                ? 'hidden'
                : 'h-4 border-r border-gray-200'
            "
          ></div>
        }
      </div>

      @if (companyInfo(); as info) {
        <div class="flex flex-col gap-2 font-medium">
          <div
            class="flex flex-col md:flex-row md:items-center gap-2 *:text-sm *:text-gray-500"
          >
            <p>상호명 : {{ info.name }}</p>
            <p>대표자명 : {{ info.ceoName }}</p>
            <p>주소 : {{ info.address }}</p>
          </div>
          <div
            class="flex flex-col md:flex-row md:items-center gap-2 *:text-sm *:text-gray-500"
          >
            <p class="text-gray-500">사업자 등록번호 : {{ info.companyNo }}</p>
            <p class="text-gray-500">
              통신판매업 번호 : {{ info.companySaleNo }}
            </p>
          </div>
          <div
            class="flex flex-col md:flex-row md:items-center gap-2 *:text-sm *:text-gray-500"
          >
            <p class="text-sm text-gray-500">
              이메일 :
              {{ info.email }}
            </p>
            <p class="text-gray-500">고객센터 : {{ info.customerCenter }}</p>
          </div>
        </div>
      }

      <p class="text-sm text-gray-500">
        COPYRIGHT (C) {{ date | date: 'y' }}. WINGS Co., Ltd. All rights
        reserved.
      </p>
    </div>
  </div>
</section>
