@if (isMobile()) {
  <app-page-modal-container
    goBackUrl="/schedule"
    [slideEnabled]="slideEnabled()"
  >
    <section class="w-full pb-20">
      <app-customer-image-banner />
      <ng-container *ngTemplateOutlet="content" />
    </section>
  </app-page-modal-container>
} @else {
  <app-customer-image-banner class="hidden xl:block" />

  <app-container class="hidden xl:block pb-[3.5rem]">
    <ng-container *ngTemplateOutlet="content" />
  </app-container>
}

<ng-template #content>
  <section
    class="container flex flex-col gap-4 px-4 py-4 mx-auto xl:py-0 xs:py-10 xl:px-0 xs:px-0 xl:gap-8 xl:mx-0"
  >
    @if (visibleSegment()) {
      <app-segment
        class="pt-[3.5rem]"
        color="black"
        [options]="segments"
        [(value)]="segment"
        (valueChange)="handleRouteChange($event)"
      />
    }
    <router-outlet />
  </section>
</ng-template>
