<main
  class="xl:hidden overflow-x-hidden absolute z-[999] inset-0 w-full h-screen bg-white"
>
  <section class="bg-primary fixed top-0 z-10 flex flex-col w-full">
    <section class="flex justify-between w-full p-4">
      <app-icon
        (click)="goBack($event)"
        name="material-symbols:arrow-back-ios"
        class="text-white cursor-pointer"
        size="sm"
      />

      <ng-content select="[icons]" />
    </section>
    <section class="p-4 flex items-center justify-between min-h-[6.25rem]">
      <p class="text-2xl font-semibold text-white">마이 페이지</p>
      <img src="my_page.svg" class="size-16" />
    </section>
    <section class="bg-white">
      <app-segment
        [(value)]="segment"
        (valueChange)="onSegmentChange($event)"
        [options]="segmentOptions()"
      />
    </section>
  </section>
  <section class="z-[999] bg-white h-full pb-20 pt-[12rem]">
    <ng-content />
  </section>
  <section class="fixed bottom-0 w-full bg-white border-t border-gray-100">
    <ng-content select="[footer]" />
  </section>
</main>
