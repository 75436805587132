@if (centerChildGuard()) {
  <ng-content />
} @else {
  <div
    class="flex flex-col gap-8 w-full min-h-[50rem] h-full items-center justify-center"
  >
    <app-icon
      name="material-symbols:person-cancel-rounded"
      class="!size-14 text-gray-300"
    />
    <div class="flex flex-col gap-3 items-center">
      <p class="text-gray-800 text-2xl font-semibold">열람할 수 없습니다.</p>
      <p class="text-gray-500 text-sm">
        등록되지 않은 아동이거나 현재 센터에 소속되지 않은 아동입니다.
      </p>
    </div>
    <app-button routerLink="/child">목록으로</app-button>
  </div>
}
