<app-container>
  <ng-container *ngTemplateOutlet="content" />
</app-container>

<ng-template #content>
  <main
    class="flex xl:flex-row flex-col w-full gap-6 xl:py-[3.75rem] p-4 xl:p-0"
  >
    <ng-container *ngTemplateOutlet="side" />
    @if (center(); as center) {
      <section class="w-full h-full">
        <router-outlet />
      </section>
    }
  </main>
</ng-template>

<ng-template #side>
  <section class="flex flex-col w-full xl:max-w-[18.125rem]">
    <div class="flex flex-col items-start self-start flex-1 w-full gap-3">
      <div
        class="w-full border border-gray-100 rounded-[20px] flex items-center flex-col overflow-hidden"
      >
        @if (center(); as center) {
          <div
            class="p-5 xl:py-10 flex flex-col gap-6 items-center justify-center w-full"
          >
            <app-center-icon size="lg" [color]="center.color" />
            <div class="flex flex-col items-center gap-2">
              <p class="text-xl font-semibold text-gray-800">
                {{ center.name }}
              </p>
              <div class="flex items-center gap-2">
                <p class="text-gray-800 font-medium">{{ user()?.name }}</p>
                <app-center-role-badge [center]="center" />
              </div>
            </div>
          </div>

          <div class="flex divide-x w-full">
            <button
              class="flex gap-2 items-center justify-center transition-all w-full p-3 bg-gray-50 hover:bg-gray-100 active:bg-gray-200"
            >
              <div
                [ngClass]="'bg-' + center.color + '-500'"
                class="size-4 rounded-full"
              ></div>
              <p class="text-gray-500 text-sm font-medium">대표 색상 변경</p>
            </button>
            <button
              class="flex gap-2 items-center justify-center transition-all w-full p-3 bg-gray-50 hover:bg-gray-100 active:bg-gray-200"
            >
              <app-icon
                name="material-symbols:edit"
                class="size-4 text-gray-500"
              />
              <p class="text-gray-500 text-sm font-medium">내 정보 수정</p>
            </button>
          </div>
        }
      </div>

      <div class="xl:flex hidden w-full flex-col gap-3">
        @for (option of options; track $index) {
          <div
            class="w-full p-5 font-semibold text-gray-800 transition-all duration-300 ease-in-out cursor-pointer rounded-xl hover:bg-gray-50"
            [routerLinkActive]="['bg-primary-50', 'hover:bg-primary-50']"
            [routerLink]="'/center/' + option.value"
          >
            {{ option.label }}
          </div>
        }
      </div>
      <app-segment
        scrollEnabled
        class="xl:hidden"
        [options]="options"
        [(value)]="segment"
        (valueChange)="onSegmentChange($event)"
      />
    </div>
  </section>
</ng-template>
