import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { initializeApp } from 'firebase/app';
import { environment } from '../env/env';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'client';

  ngOnInit(): void {
    initializeApp(environment.firebase);
  }
}
