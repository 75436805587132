<div class="flex flex-col w-full h-full gap-4 md:p-2 p-4">
  @if (centers() && centers()!.length > 0) {
    <div class="flex flex-col gap-2 min-w-[15rem]">
      @for (center of centers(); track $index) {
        <app-center-profile
          [active]="currentCenter()?.id === center.id"
          (click)="select.emit(center)"
          [center]="center"
          class="cursor-pointer"
        />
      }
    </div>
    <app-button
      expand
      routerLink="/my-page/center"
      iconClass="text-gray-500"
      variant="outline"
      color="gray"
      icon="fluent:textbox-more-24-filled"
      >전체 센터보기</app-button
    >
  } @else {
    <div
      class="flex flex-col w-full min-h-[10rem] items-center justify-center h-full min-w-[15rem]"
    >
      <div class="flex flex-col gap-3 items-center">
        <p class="text-gray-800 font-semibold">소속된 센터가 없어요.</p>
        <p class="text-gray-500 text-sm">
          센터를 찾거나 직접 센터를 등록해주세요.
        </p>
      </div>
    </div>
    <div class="flex gap-3 items-center">
      <app-button
        expand
        routerLink="/center/form"
        iconClass="text-primary"
        icon="mynaui:heart-home-solid"
        variant="outline"
        color="gray"
        >센터 생성</app-button
      >
      <app-button expand icon="ic:round-search">센터 찾기</app-button>
    </div>
  }
</div>
