import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CLIENT_ACCESS_TOKEN_KEY } from '@malirang/common';
import { AlertService, LocalStorageService } from '@malirang/front-share';
import { catchError, throwError } from 'rxjs';

export const HttpInterceptor: HttpInterceptorFn = (req, next) => {
  const localStorageService = inject(LocalStorageService);
  const alertService = inject(AlertService);
  const router = inject(Router);
  const accessToken = localStorageService.get(CLIENT_ACCESS_TOKEN_KEY);
  const request = req.clone({
    setHeaders: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return next(request).pipe(
    catchError((e) => {
      if (e.status === 401) {
        localStorageService.remove(CLIENT_ACCESS_TOKEN_KEY);
        router.navigate(['/login']);
      }

      if (e.status === 497) {
        const alert = alertService.open({
          type: 'danger',
          title: '알림',
          content: '차단된 회원입니다.',
          buttons: {
            confirm: { text: '확인', color: 'red' },
          },
        });

        alert.closed.subscribe({
          next: () => {
            localStorageService.remove(CLIENT_ACCESS_TOKEN_KEY);
            window.location.reload();
          },
        });
      }

      return throwError(() => e.error);
    }),
  );
};
