<section
  id="mobile-footer"
  class="fixed bottom-0 w-full *:bg-white border-t border-gray-200 z-50"
>
  <div class="grid items-center grid-cols-5 py-2">
    @for (i of currentMenus(); track $index) {
      <div class="flex flex-col items-center gap-1" [routerLink]="i.url">
        <app-icon
          routerLinkActive="text-primary"
          [name]="i.icon"
          class="text-gray-400"
        />

        <p
          class="text-xs font-semibold text-gray-400 min-w-max"
          routerLinkActive="text-primary"
        >
          {{ i.title }}
        </p>
      </div>
    }
  </div>
</section>
