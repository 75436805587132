import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CenterDto, CenterService } from '@malirang/api-client';
import { BaseStore, LocalStorageService } from '@malirang/front-share';
import { map } from 'rxjs';
import { UserStore } from './user.store';

const CENTER_STORE_KEY = 'd3ad24ef-c4c2-4512-b26c-9514a1eb555c';

type State = {
  currentCenter: CenterDto | null;
  centers: CenterDto[];
};

@Injectable({ providedIn: 'root' })
export class CenterStore extends BaseStore<State> {
  private readonly localStorageService = inject(LocalStorageService);
  private readonly userStore = inject(UserStore);
  private readonly centerService = inject(CenterService);

  private readonly user = this.userStore.user;
  private readonly localStorageKey = computed(
    () => `${CENTER_STORE_KEY}-${this.user()?.id}`,
  );

  centers = toSignal(this.state$.pipe(map((state) => state.centers)));
  currentCenter = toSignal(
    this.state$.pipe(map((state) => state.currentCenter)),
  );

  constructor() {
    super({
      currentCenter: null,
      centers: [],
    });
  }

  fetchCenters() {
    this.centerService.centerControllerGetMyCenterList().subscribe({
      next: (centers) => {
        this.setCenters(centers);
      },
    });
  }

  setCurrentCenter(center: CenterDto) {
    this.updateState({ currentCenter: center });
    this.localStorageService.set(this.localStorageKey(), center.id);
  }

  setCenters(centers: CenterDto[]) {
    this.updateState({ centers });

    if (!this.currentCenter()) {
      const centerId = this.localStorageService.get(this.localStorageKey());
      if (centerId) {
        const center = centers.find((c) => c.id === centerId);
        if (center) {
          this.setCurrentCenter(center);
        } else {
          this.setCurrentCenter(centers[0]);
        }
      } else {
        this.setCurrentCenter(centers[0]);
      }
    }
  }
}
