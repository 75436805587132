<section (click)="handleOpen($event)" class="relative md:hidden">
  <ng-container *ngTemplateOutlet="content" />
</section>

<section class="relative md:flex hidden">
  <div [cdkMenuTriggerFor]="menu">
    <ng-container *ngTemplateOutlet="content" />
  </div>
</section>

<ng-template #content>
  <div
    class="flex items-center gap-2 px-3 py-2 transition-all duration-300 ease-in-out border rounded-xl cursor-pointer select-none w-fit border-primary-300 bg-primary-50"
  >
    <app-icon size="sm" name="mynaui:heart-home-solid" class="text-primary" />
    <p class="hidden text-sm text-primary sm:block w-[8rem] truncate">
      @if (currentCenter(); as center) {
        {{ center.name }}
      } @else {
        소속된 센터가 없습니다.
      }
    </p>
    <app-icon
      name="material-symbols:keyboard-arrow-down-rounded"
      class="text-primary"
      size="sm"
    />
  </div>
</ng-template>

<ng-template #menu>
  <div class="flex flex-col gap-4 bg-white rounded-xl shadow-lg" cdkMenu>
    <app-select-center-content
      [currentCenter]="currentCenter()!"
      (select)="handleSelect($event!)"
      [centers]="centers() || []"
    />
  </div>
</ng-template>
