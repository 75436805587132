@if (center(); as center) {
  <div
    class="px-3 py-2 border-2 transition-all duration-300 ease-in-out rounded-lg"
    expand
    [ngClass]="active() ? 'bg-gray-50 border-primary' : 'border-transparent'"
  >
    <div class="flex items-center gap-4 justify-between w-full">
      <div class="flex items-center gap-2">
        <div class="flex items-center gap-3">
          <app-center-icon [color]="center.color" />
        </div>
        <div class="flex flex-col items-start gap-2">
          <app-center-role-badge [center]="center" />
          <p class="text-sm text-gray-800">
            {{ center.name }}
          </p>
        </div>
      </div>

      @if (active()) {
        <app-icon
          name="material-symbols:check-rounded"
          class="text-primary"
          size="md"
        />
      }
    </div>
  </div>
}
