<app-container bgColor="bg-primary-50 bo border-gray-100">
  <section
    class="container flex items-center justify-between w-full px-4 py-8 mx-auto xs:px-0 xl:px-4 xl:py-12 xl:mx-0"
  >
    <p class="text-2xl font-semibold text-gray-800 xl:text-5xl xl:font-bold">
      고객센터
    </p>
    <div class="flex items-center gap-3 xl:gap-6">
      <img
        class="self-center xl:max-w-[140px] max-w-[60px] aspect-square"
        src="/cs-center/ellipsis-icon.svg"
      />
      <img
        class="self-center xl:max-w-[140px] max-w-[60px] aspect-square"
        src="/cs-center/speaker-icon.svg"
      />
    </div>
  </section>
</app-container>
