import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { CenterDto } from '@malirang/api-client';
import {
  ActionSheetHeader,
  ModalAdapter,
  SLIDE_Y,
} from '@malirang/front-share';
import { CenterStore } from 'apps/client/src/app/stores/center.store';
import { SelectCenterContentComponent } from '../../select-center/content/select-center-content.component';

@Component({
  selector: 'app-select-center-modal',
  templateUrl: './select-center.modal.html',
  imports: [CommonModule, ActionSheetHeader, SelectCenterContentComponent],
  animations: [SLIDE_Y],
})
export class SelectCenterModal extends ModalAdapter {
  readonly centerStore = inject(CenterStore);

  currentCenter = this.centerStore.currentCenter;

  selectCenter(center: CenterDto) {
    this.centerStore.setCurrentCenter(center);
    this.close();
  }
}
