<header id="main-header" class="w-full bg-white select-none">
  <div
    class="bg-white w-full xl:container xl:mx-auto border-b border-gray-200 xl:border-none flex items-center justify-between py-3 xl:py-2.5 px-4 xl:px-0"
  >
    <img
      [draggable]="false"
      class="h-full transition-all duration-300 ease-in-out cursor-pointer xl:my-1 xl:h-full xl:max-h-8 max-h-6 hover:opacity-70"
      routerLink="/schedule"
      src="logo.svg"
      alt="logo"
    />
    <div class="flex items-center justify-center gap-3">
      @if (user(); as user) {
        <app-select-center />
        <div class="flex items-center gap-3 xl:hidden">
          <app-icon
            name="solar:bell-bold-duotone"
            class="text-gray-500 !size-7"
          />
          <app-icon
            (click)="openSidemenu()"
            name="material-symbols:menu-rounded"
            class="text-gray-500 !size-7"
          />
        </div>
      }
      <div class="items-center justify-center hidden gap-5 xl:flex">
        @if (user(); as user) {
          <div
            class="flex items-center gap-1 transition-all duration-300 ease-in-out cursor-pointer select-none hover:opacity-80"
            [cdkMenuTriggerFor]="myPageMenu"
          >
            <app-icon
              name="solar:user-circle-bold-duotone"
              class="text-gray-500"
              size="md"
            />

            <span class="text-sm font-normal text-gray-500 shrink-0"
              >{{ user.name }} 님</span
            >
          </div>
          <a
            (click)="logout()"
            class="flex items-center gap-1 transition-all duration-300 ease-in-out cursor-pointer select-none hover:opacity-80"
          >
            <app-icon
              name="solar:logout-2-bold-duotone"
              class="text-gray-500"
              size="md"
            />

            <span class="text-sm font-normal text-gray-500"> 로그아웃</span>
          </a>
          <app-icon
            name="solar:bell-bold-duotone"
            class="text-gray-500"
            size="lg"
          />
        } @else {
          <a
            (click)="login()"
            class="flex items-center gap-1 transition-all duration-300 ease-in-out cursor-pointer select-none hover:opacity-80"
          >
            <app-icon
              name="solar:login-2-bold-duotone"
              class="text-gray-500"
              size="md"
            />
            <span class="text-sm font-normal text-gray-500"> 로그인</span>
          </a>
        }
        <button
          routerLink="subscription"
          class="flex items-center gap-1 px-3 py-2 transition-all duration-300 ease-in-out rounded-lg hover:opacity-80"
          style="
            background: linear-gradient(
              270deg,
              #1693c9 0%,
              #4f5cef 63.36%,
              #6c4fef 92.5%
            );
          "
        >
          <img
            src="malirang-white.svg"
            class="w-full h-full max-w-14 max-h-4"
            alt="malirang white logo"
          />
          <span class="text-sm text-white">구독권/이용권</span>
        </button>
      </div>
    </div>
  </div>
</header>

<section
  class="z-[999] fixed top-[60px] w-full hidden bg-primary xl:block select-none"
>
  <div class="container grid grid-cols-6 py-5 mx-auto custom-grid">
    @for (item of menus(); track $index) {
      <a
        [routerLink]="item.url"
        class="flex items-center gap-2 transition-all duration-300 ease-in-out cursor-pointer menu-item hover:opacity-70"
      >
        <app-icon
          [name]="item.icon"
          class="text-primary-300"
          size="md"
          routerLinkActive="text-white"
        />
        <span class="text-primary-300" routerLinkActive="text-white">{{
          item.title
        }}</span>
      </a>
    }
  </div>
</section>

<ng-template #myPageMenu>
  @if (user(); as user) {
    <div
      cdkMenu
      class="flex flex-col gap-4 p-4 my-[22px] bg-white rounded-xl shadow-lg"
      style="width: 268px"
    >
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-1">
          <img
            src="/subscription/business.svg"
            class="size-5"
            alt="business logo"
          />
          <p class="font-bold text-gray-800">{{ user.name }} 선생님</p>
          <app-center-role-badge [center]="center()!" />
        </div>
        <p class="text-xs text-gray-400">{{ user.auths[0].email }}</p>
      </div>
      <div class="w-full border-b border-gray-100"></div>

      <div class="flex flex-col gap-1">
        @for (item of menuItems; track item.url) {
          <button cdkMenuItem [routerLink]="item.url" class="item-button">
            <div class="flex items-center gap-2">
              <app-icon
                routerLinkActive="text-primary"
                [name]="item.icon"
                class="text-gray-500"
              />
              <p routerLinkActive="text-primary">{{ item.label }}</p>
            </div>

            <app-icon
              name="material-symbols:arrow-forward-ios"
              routerLinkActive="text-primary "
              class="text-gray-300 cursor-pointer"
              size="sm"
            />
          </button>
        }
      </div>
    </div>
  }
</ng-template>
