import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { CenterDto } from '@malirang/api-client';
import { UserStore } from '../../../stores/user.store';

@Component({
  selector: 'app-center-role-badge',
  template: `
    @if (center(); as center) {
      @if (center.myRole) {
        <div
          [ngClass]="
            role() === '관리자'
              ? 'text-gray-500 bg-gray-100'
              : 'bg-primary-50 text-primary'
          "
          class="px-2 py-1 text-xs rounded-lg"
        >
          {{ role() }}
        </div>
      } @else {
        <div class=" px-2 py-1 text-xs rounded-lg bg-neutral-300 text-white ">
          미소속
        </div>
      }
    }
  `,
  imports: [CommonModule],
})
export class CenterRoleBadgeComponent {
  readonly userStore = inject(UserStore);

  center = input.required<CenterDto>();
  role = computed(() =>
    this.center()?.owner?.userId === this.userStore.user()?.id
      ? '관리자'
      : '선생님',
  );
}
