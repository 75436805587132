import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Icon } from '@malirang/front-share';
import { MenuItem } from '../../layout.page';

@Component({
  selector: 'app-mobile-footer',
  standalone: true,
  imports: [CommonModule, RouterModule, Icon],
  templateUrl: './mobile-footer.component.html',
  styleUrl: './mobile-footer.component.scss',
})
export class MobileFooterComponent {
  menus = input.required<MenuItem[]>();

  currentMenus = computed(() =>
    this.menus().filter((menu) => !menu.mobileHidden),
  );
}
