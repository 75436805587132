import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { UserDto, UserService } from '@malirang/api-client';
import { CLIENT_ACCESS_TOKEN_KEY } from '@malirang/common';
import { BaseStore, LocalStorageService } from '@malirang/front-share';
import { lastValueFrom, map, share } from 'rxjs';

type State = {
  user: UserDto | null;
};

@Injectable({ providedIn: 'root' })
export class UserStore extends BaseStore<State> {
  readonly userService = inject(UserService);
  readonly localStorageService = inject(LocalStorageService);
  readonly router = inject(Router);

  user$ = this.state$.pipe(map((state) => state.user));
  user = toSignal(this.user$);
  isAuth = toSignal(this.state$.pipe(map((state) => !!state.user)));

  constructor() {
    super({
      user: null,
    });
  }

  async fetchUser(): Promise<UserDto> {
    return await lastValueFrom(
      this.userService.userControllerGetMe().pipe(share()),
    );
  }

  setUser(user: UserDto) {
    this.updateState({ user });
  }

  clearUser() {
    this.updateState({ user: null });
  }

  logout() {
    this.clearUser();
    this.localStorageService.remove(CLIENT_ACCESS_TOKEN_KEY);
    this.router.navigate(['/login']);
  }
}
