import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { InfoService } from '@malirang/api-client';
import { Terms, TermType } from '@malirang/common';
import { ModalService } from '@malirang/front-share';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  readonly infoService = inject(InfoService);
  readonly modalService = inject(ModalService);

  date = new Date();

  $companyInfo = rxResource({
    loader: () => this.infoService.infoControllerGetCompanyInfoV1(),
  });
  companyInfo = this.$companyInfo.value;

  termType = TermType;
  terms = Terms;

  goToLink(link: string) {
    if (link === 'kakao') {
      window.open('https://pf.kakao.com/_JrudG', '_blank');
    } else if (link === 'naver') {
      window.open('https://blog.naver.com/wings_im', '_blank');
    } else if (link === 'insta') {
      window.open('https://www.instagram.com/malirang_official/', '_blank');
    }
  }
}
