<section @slideY class="fixed bottom-0 bg-white w-full left-0 rounded-t-xl">
  <app-action-sheet-header icon="mynaui:heart-home-solid" (close)="close()"
    >센터 선택</app-action-sheet-header
  >
  <app-select-center-content
    [currentCenter]="currentCenter()!"
    [centers]="props()?.items"
    (select)="selectCenter($event)"
  />
</section>
