import { booleanAttribute, Component, HostBinding, input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './container.component.html',
  styleUrl: './container.component.scss',
})
export class ContainerComponent {
  @HostBinding('class') hostClass = 'size-full';
  bgColor = input<string>('white');
  expand = input<boolean, string | boolean>(false, {
    transform: booleanAttribute,
  });
}
