import { CommonModule } from '@angular/common';
import { Component, inject, model, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {
  Icon,
  PageModalContainer,
  Segment,
  SegmentOption,
} from '@malirang/front-share';
import { CenterIconComponent } from '../../components/center/icon/center-icon.component';
import { CenterRoleBadgeComponent } from '../../components/center/role-badge/center-role-badge.component';
import { ContainerComponent } from '../../components/container/container.component';
import { UserStore } from '../../stores/user.store';
import { CenterPageAdapter } from './adapters/center-page.adapter';
import { injectNavigationEnd } from 'ngxtension/navigation-end';

@Component({
  selector: 'app-center-layout',
  templateUrl: './center.layout.html',
  imports: [
    RouterOutlet,
    ContainerComponent,
    PageModalContainer,
    CommonModule,
    CenterRoleBadgeComponent,
    CenterIconComponent,
    RouterLink,
    RouterLinkActive,
    Icon,
    Segment,
  ],
})
export class CenterLayout extends CenterPageAdapter implements OnInit {
  readonly userStore = inject(UserStore);
  readonly navigationEnd$ = injectNavigationEnd();

  user = this.userStore.user;

  segment = model('info');

  options: SegmentOption[] = [
    { label: '센터 정보', value: 'info' },
    { label: '센터 구성원', value: 'person' },
    { label: '공지사항', value: 'notice' },
    { label: '템플릿 관리', value: 'template' },
    { label: '결제 관리', value: 'payment' },
    { label: '알림', value: 'notification' },
  ];

  ngOnInit(): void {
    this.segment.set(this.router.url.split('/').pop() || 'center');

    this.navigationEnd$.subscribe(() => {
      this.segment.set(this.router.url.split('/').pop() || 'center');
    });
  }

  onSegmentChange(value: string): void {
    this.router.navigate(['/center', value]);
  }
}
