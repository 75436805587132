/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CenterChildDto } from '@malirang/api-client';
import { Button, Icon } from '@malirang/front-share';
import { CenterStore } from '../../../stores/center.store';

@Component({
  selector: 'app-center-child-guard',
  templateUrl: './child-guard.component.html',
  imports: [CommonModule, Icon, Button, RouterLink],
})
export class CenterChildGuardComponent {
  readonly centerStore = inject(CenterStore);

  currentCenter = this.centerStore.currentCenter;
  centerChild = input.required<CenterChildDto>();

  centerChildGuard = computed(
    () => this.centerChild()?.centerId === this.currentCenter()?.id,
  );
}
