import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { SegmentOption } from '@malirang/front-share';
import { CenterIconComponent } from '../../components/center/icon/center-icon.component';
import { CenterRoleBadgeComponent } from '../../components/center/role-badge/center-role-badge.component';
import { ContainerComponent } from '../../components/container/container.component';
import { CenterStore } from '../../stores/center.store';
import { UserStore } from '../../stores/user.store';
import { MyPageModalContainer } from './components/my-page-modal-container/my-page-modal-container.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { injectResize } from 'ngxtension/resize';
import { map } from 'rxjs';

@Component({
  selector: 'app-my-page-layout',
  standalone: true,
  imports: [
    CommonModule,
    ContainerComponent,
    RouterModule,
    CenterRoleBadgeComponent,
    CenterIconComponent,
    MyPageModalContainer,
    RouterOutlet,
  ],
  templateUrl: './my-page.layout.html',
  styleUrl: './my-page.layout.scss',
})
export default class MyPageLayout {
  readonly centerStore = inject(CenterStore);
  readonly userStore = inject(UserStore);
  readonly router = inject(Router);

  user = this.userStore.user;
  currentCenter = this.centerStore.currentCenter;
  isMobile = toSignal(
    injectResize().pipe(map((size) => size.width < 768)) ||
      window.innerWidth < 768,
  );

  segmentOptions: SegmentOption[] = [
    { label: '내정보 관리', value: 'info' },
    { label: '소속 센터', value: 'center' },
    { label: '결제 관리', value: 'payment' },
    { label: '알림', value: 'notification' },
  ];
}
