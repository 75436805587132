import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { CenterDto } from '@malirang/api-client';
import { Icon, ModalService } from '@malirang/front-share';
import { CenterStore } from '../../../stores/center.store';
import { SelectCenterModal } from '../modals/select-center/select-center.modal';
import { SelectCenterContentComponent } from './content/select-center-content.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-center',
  templateUrl: './select-center.component.html',
  imports: [CommonModule, Icon, CdkMenuModule, SelectCenterContentComponent],
})
export class SelectCenterComponent {
  readonly modalService = inject(ModalService);
  readonly centerStore = inject(CenterStore);
  readonly router = inject(Router);

  centers = this.centerStore.centers;
  currentCenter = this.centerStore.currentCenter;

  open = signal<boolean>(false);

  handleOpen(ev: any) {
    ev.preventDefault();
    ev.stopPropagation();

    if (window.innerWidth < 1024) {
      this.modalService.create(SelectCenterModal, {
        disableClose: false,
        componentProps: {
          currentCenter: this.currentCenter(),
          items: this.centers(),
        },
      });
    } else {
      this.open.set(true);
    }
  }

  handleSelect(center: CenterDto) {
    this.centerStore.setCurrentCenter(center);
    this.router.navigate(['/schedule']);
  }
}
