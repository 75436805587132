import { CommonModule } from '@angular/common';
import { Component, inject, model, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  PageModalContainer,
  Segment,
  SegmentOption,
} from '@malirang/front-share';
import { injectNavigationEnd } from 'ngxtension/navigation-end';
import { injectResize } from 'ngxtension/resize';
import { map } from 'rxjs';
import { ContainerComponent } from '../../components/container/container.component';
import CustomerImageBannerComponent from './components/customer-image-banner/customer-image-banner.component';

type ActivityType = 'notice' | 'inquiry' | 'faq';

@Component({
  selector: 'app-customer-layout',
  standalone: true,
  imports: [
    CommonModule,
    ContainerComponent,
    RouterModule,
    FormsModule,
    CustomerImageBannerComponent,
    PageModalContainer,
    Segment,
  ],
  templateUrl: './customer.layout.html',
  styleUrl: './customer.layout.scss',
})
export default class CustomerLayout implements OnInit {
  readonly router = inject(Router);
  readonly route = inject(ActivatedRoute);

  isMobile = toSignal(
    injectResize().pipe(map((size) => size.width < 768)) ||
      window.innerWidth < 768,
  );
  slideEnabled = signal(true);
  visibleSegment = signal(true);
  navigationEnd$ = injectNavigationEnd();

  segment = model<ActivityType>('notice');
  segments: SegmentOption[] = [
    { label: '공지사항', value: 'notice' },
    { label: '1:1문의', value: 'inquiry' },
    { label: 'FAQ', value: 'faq' },
  ];

  ngOnInit(): void {
    this.handleSegment();
    this.handleVisibleSegmentChange();

    this.navigationEnd$.subscribe(() => {
      this.handleVisibleSegmentChange();
      this.handleSegment();
    });
  }

  handleRouteChange(ev: string) {
    this.router.navigate(['/customer', ev]);
  }

  handleVisibleSegmentChange() {
    const path = this.router.url.split('/').pop();
    if (path === 'notice' || path === 'faq' || path === 'inquiry') {
      this.visibleSegment.set(true);
    } else {
      this.visibleSegment.set(false);
    }
  }

  handleSegment() {
    const path = this.router.url.split('/').pop();
    if (path) {
      this.segment.set(path as ActivityType);
    }
  }
}
