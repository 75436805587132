import { CommonModule } from '@angular/common';
import { Component, inject, input, model } from '@angular/core';
import { Router } from '@angular/router';
import { Icon, Segment, SegmentOption } from '@malirang/front-share';
import { injectNavigationEnd } from 'ngxtension/navigation-end';

@Component({
  selector: 'app-my-page-modal-container',
  standalone: true,
  imports: [CommonModule, Icon, Segment],
  templateUrl: './my-page-modal-container.component.html',
  styleUrl: './my-page-modal-container.component.scss',
})
export class MyPageModalContainer {
  readonly router = inject(Router);

  backUrl = input('');
  segmentOptions = input<SegmentOption[]>([]);

  navigationEnd$ = injectNavigationEnd();
  segment = model('center');

  ngOnInit(): void {
    this.segment.set(this.router.url.split('/').pop() || 'center');

    this.navigationEnd$.subscribe(() => {
      this.segment.set(this.router.url.split('/').pop() || 'center');
    });
  }

  goBack(ev?: Event) {
    ev?.stopPropagation();
    ev?.preventDefault();

    if (this.backUrl()) {
      this.router.navigate([this.backUrl()], { replaceUrl: true });
      return;
    }
  }

  onSegmentChange(segment: string) {
    this.router.navigate(['/my-page', segment]);
  }
}
