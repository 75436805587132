import { Route } from '@angular/router';
import { CenterLayout } from './pages/center/center.layout';
import { DetailChildLayout } from './pages/child/detail-child/layout/detail-child.layout';
import CustomerLayout from './pages/customer/customer.layout';
import LayoutPage from './pages/layout/layout.page';
import MyPageLayout from './pages/my-page/my-page.layout';

export const appRoutes: Route[] = [
  {
    path: '',
    data: { layout: 'fullscreen' },
    children: [
      { path: '', loadComponent: () => import('./pages/landing/landing.page') },
      {
        path: 'login',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/auth/login/login.page'),
          },
          {
            path: ':provider',
            loadComponent: () => import('./pages/auth/login/login.page'),
          },
        ],
      },
      {
        path: 'sign-up',
        loadComponent: () => import('./pages/auth/sign-up/sign-up.page'),
      },
      {
        path: 'find-email',
        loadComponent: () => import('./pages/auth/find-email/find-email.page'),
      },
      {
        path: 'find-password',
        loadComponent: () =>
          import('./pages/auth/find-password/find-password.page'),
      },
      {
        path: 'term',
        children: [
          {
            path: ':value',
            loadComponent: () => import('./pages/auth/term/term.page'),
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: LayoutPage,
    children: [
      {
        path: 'center',
        component: CenterLayout,
        title: '센터 관리',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'info',
          },
          {
            path: 'info',
            loadComponent: () => import('./pages/center/info/center-info.page'),
          },
          {
            path: 'person',
            loadComponent: () =>
              import('./pages/center/person/center-person.page'),
          },
          {
            path: 'notice',
            loadComponent: () =>
              import('./pages/center/notice/center-notice.page'),
          },
          {
            path: 'template',
            loadComponent: () =>
              import('./pages/center/template/center-template.page'),
          },
          {
            path: 'payment',
            loadComponent: () =>
              import('./pages/center/payment/center-payment.page'),
          },
          {
            path: 'notification',
            loadComponent: () =>
              import('./pages/center/notification/center-notification.page'),
          },
        ],
      },
      {
        path: 'center/form',
        loadComponent: () => import('./pages/center/form/center-form.page'),
      },
      {
        path: 'subscription',
        loadComponent: () => import('./pages/subscription/subscription.page'),
      },
      {
        path: 'schedule',
        loadComponent: () => import('./pages/schedule/schedule.page'),
      },
      {
        path: 'my-page',
        component: MyPageLayout,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'info',
          },
          {
            path: 'info',
            loadComponent: () =>
              import('./pages/my-page/info/my-page-info.page'),
          },
          {
            path: 'center',
            loadComponent: () =>
              import('./pages/my-page/center/my-page-center.page'),
          },
          {
            path: 'payment',
            loadComponent: () =>
              import('./pages/my-page/payment/my-page-payment.page'),
          },
          {
            path: 'notification',
            loadComponent: () =>
              import('./pages/my-page/notification/my-page-notification.page'),
          },
        ],
      },
      {
        path: 'guardian',
        children: [
          {
            path: 'form',
            loadComponent: () =>
              import('./pages/guardian/create-guardian/create-guardian.page'),
          },
        ],
      },
      {
        path: 'child',
        children: [
          {
            path: '',
            title: '아동 목록',
            loadComponent: () => import('./pages/child/child.page'),
          },
          {
            path: 'find',
            title: '아동 찾기',
            loadComponent: () =>
              import('./pages/child/find-child/find-child.page'),
          },
          {
            path: 'form',
            title: '아동 등록',
            loadComponent: () =>
              import('./pages/child/find-child/create-child/create-child.page'),
          },
          {
            path: ':id/detail/:childId',
            component: DetailChildLayout,
            children: [
              {
                path: '',
                title: '아동 상세',
                loadComponent: () =>
                  import('./pages/child/detail-child/detail-child.page'),
              },
              {
                path: 'guardian/:guardianId',
                title: '보호자 상세',
                loadComponent: () =>
                  import(
                    './pages/child/detail-child/guardian/detail-guardian/detail-guardian.page'
                  ),
              },
              {
                path: 'ticket/:ticketId',
                title: '발달서비스 상세',
                loadComponent: () =>
                  import(
                    './pages/child/detail-child/ticket/detail-ticket/detail-ticket.page'
                  ),
              },
            ],
          },
        ],
      },

      {
        path: 'customer',
        component: CustomerLayout,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'notice',
          },
          {
            path: 'notice',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./pages/customer/notice/notice.page'),
              },
              {
                path: ':id',
                loadComponent: () =>
                  import(
                    './pages/customer/notice/detail-notice/detail-notice.page'
                  ),
              },
            ],
          },
          {
            path: 'inquiry',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('./pages/customer/inquiry/inquiry.page'),
              },
              {
                path: 'form',
                loadComponent: () =>
                  import(
                    './pages/customer/inquiry/create-inquiry/create-inquiry.page'
                  ),
              },
              {
                path: ':id',
                loadComponent: () =>
                  import(
                    './pages/customer/inquiry/detail-inquiry/detail-inquiry.page'
                  ),
              },
            ],
          },
          {
            path: 'faq',
            loadComponent: () => import('./pages/customer/faq/faq.page'),
          },
        ],
      },
      {
        path: 'ticket',
        children: [
          {
            path: 'form',
            loadComponent: () =>
              import('./pages/ticket/create-ticket/create-ticket.page'),
          },
        ],
      },
      {
        path: 'withdraw',
        loadComponent: () => import('./pages/my-page/withdraw/withdraw.page'),
      },
      {
        path: 'change-contact',
        loadComponent: () =>
          import('./pages/my-page/info/change-contact/change-contact.page'),
      },
      {
        path: 'change-password',
        loadComponent: () =>
          import('./pages/my-page/info/change-password/change-password.page'),
      },
      {
        path: 'change-email',
        loadComponent: () =>
          import('./pages/my-page/info/change-email/change-email.page'),
      },
    ],
  },
];
