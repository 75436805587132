import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ContainerComponent } from 'apps/client/src/app/components/container/container.component';

@Component({
  selector: 'app-customer-image-banner',
  standalone: true,
  imports: [CommonModule, ContainerComponent],
  templateUrl: './customer-image-banner.component.html',
  styleUrl: './customer-image-banner.component.scss',
})
export default class CustomerImageBannerComponent {}
